import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

class CategorySummary extends React.Component {
    render() {
        const { category } = this.props;
        const link = category.uri.substring(category.uri.lastIndexOf("-") + 1);
        return (
             <Grid item xs={12} md={6} xl={3} style={{display: 'flex'}}>
                     <Card key={link}>
                        <CardActionArea style={{height: '100%'}} href={"/"+link}>
                        <CardMedia
                            component="img"
                            height="200"
                            image={category.featuredImage.node.mediaItemUrl}

                        />
                            <CardContent  >
                                <Typography gutterBottom variant="h5" component="div">
                                    {category.title}
                                </Typography>
                                <Typography variant="body2" style={{fontSize: '1em'}} color="text.secondary"  component="div">
                                    <span dangerouslySetInnerHTML={{ __html: category.headstartPages.abstract }} />
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card> 
                </Grid>



        )
    }
}

export default class CategoryList extends React.Component {
    render() {
        const { homepage, categories } = this.props;

        if (homepage.length === 0 || categories.length === 0) return null;
        return (

            <>

                <Typography  component="div">
                    <span dangerouslySetInnerHTML={{ __html: homepage[0].node.content }} />
                </Typography>
                <Grid spacing={4} container>
                    {categories.map((t) => (
                        <CategorySummary category={t.node} key={t.node.id}/>
                    ))}
                </Grid>




            </>
        )
    }
}