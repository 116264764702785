
import React from "react"
import {  graphql } from "gatsby"
import CategoryList from "../components/CategoryList"
import PageLayout from "../components/PageLayout"
import Seo from "../components/Seo"

const Index = ({data}) => {
  const posts = data.categories.edges;
  
  return (
    <PageLayout>
      <Seo title="Home" />
      <CategoryList homepage={data.home.edges} categories={posts} />
    </PageLayout>
  );
 
  
}

export default Index

export const pageQuery = graphql`
query HomeQuery {
  categories: allWpPage(
    filter: {wpParent: {node: {slug: {eq: "headstart-home"}}}}
    sort: {order: ASC, fields: menuOrder}
  ) {
    edges {
      node {
        id
        uri
        title
        content
        parentId
        databaseId
        uri
        headstartPages {
          abstract
        }
        featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
    }
  }
  home: allWpPage(filter: {slug: {eq: "headstart-home"}}) {
    edges {
      node {
        id
        uri
        title
        content
        parentId
        databaseId
        uri
        headstartPages {
          abstract
        }
      }
    }
  }
}
`
